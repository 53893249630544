"use client";

import {
  Button,
  Column,
  Heading,
  Icon,
  RichText,
  Row,
  variables,
} from "daskapital-ds";
import React, { Suspense } from "react";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import Image from "next/image";
import Link from "next/link";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import { scrollTo } from "@/lib/helpers/scrollHelpers";
import { useGlobalContext } from "@/lib/globalContext";

const Footer = ( ) => {
  const state = useGlobalContext();
  const {footer} = state;
   console.log(footer)
  return (
    <footer id="site-footer">
      <Suspense>
        {!!footer?.blocks?.length &&
          footer?.blocks?.map((block: BlocksProps, index: number) => {
            return displayBlock(block, index)
          })}
      </Suspense>
    </footer>
  );
};

export default Footer;
