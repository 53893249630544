'use client';

import { Button, clickOutSideToClose, Dropdown, DropdownItem, Heading, Icon } from 'daskapital-ds';

import { Language } from '@/lib/helpers/getI18n';
import Link from 'next/link';
import { useGlobalContext } from '@/lib/globalContext';
import { createRef, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRouter, usePathname } from 'next/navigation';

const Switcher = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
`;

const SwitcherTrigger = styled.div`
	background: transparent;
	position: relative;
	height: 40px;
	display: flex;
	align-items: center;
	gap: 8px;
	&:hover {
		cursor: pointer;
	}
`;

const SwitcherContent = styled.div`
	background: white;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: calc(40px + 8px);
	right: 0;
	left: auto;
	opacity: 0;
	transform: translate(0, -4px);
	transition: all 0.4s;
	border-radius: 4px;
	&[data-open='true'] {
		opacity: 1;
		transform: translate(0, 0);
	}
	@media all and (max-width: 768px) {
		left: 0;
		right: auto;
	}
`;

const SwitcherLink = styled.div`
	background: white;
	color: black;
	padding: 6px 12px;
	transition: all 0.4s;

	&:hover {
		cursor: pointer;
		background: rgba(240, 240, 240, 1);
	}
`;

const LanguageSwitcher = () => {
	const state = useGlobalContext();
	const { setLang } = state;
	const [selectedLanguage, setSelectedLanguage] = useState(state?.lang);
	const [toggleSwitch, setToggleSwitch] = useState(false);

	const currentLanguage = useMemo(() => {
		const languageData = state?.i18n?.languages.filter((x: Language) => x.code === selectedLanguage);
		return languageData[0].name || 'English';
	}, [selectedLanguage]);

	const findLanguageName = useCallback((code: string) => {
		if (!code) return null;
		const languageData = state?.i18n?.languages.filter((x: Language) => x.code === code);
		return languageData[0].name || 'English';
	}, []);

	const handleLanguage = useCallback(
		(locale: string) => {
			setSelectedLanguage(locale);
			setToggleSwitch(false);
			setLang && setLang(locale);
		},
		[state]
	);

	const itemsContainerRef = createRef<HTMLDivElement>();
	clickOutSideToClose(itemsContainerRef, () => setToggleSwitch(false));

	return (
		<Switcher>
			<SwitcherTrigger onClick={() => setToggleSwitch(!toggleSwitch)}>
				<span>{currentLanguage}</span>
				<Icon icon="icon-chevron-down" />
			</SwitcherTrigger>

			<SwitcherContent ref={itemsContainerRef} data-open={toggleSwitch}>
				{state?.i18n?.locales.map((locale: string) => (
					<SwitcherLink>
						<Link href={state?.translatedUri?.[locale] || `/${locale}`} prefetch={true} onClick={() => handleLanguage(locale)}>
							{findLanguageName(locale)}
						</Link>
					</SwitcherLink>
				))}
			</SwitcherContent>
		</Switcher>
	);
	// return (
	// 	<>
	// 		{state?.i18n.locales && (
	// 			<Dropdown
	// 				title={
	// 					<Heading tag={'span'} size={'texts'} style={{ color: 'currentcolor' }}>
	// 						{state?.i18n?.languages.filter((x: Language) => x.code === state.lang)[0].name}
	// 					</Heading>
	// 				}
	// 				icon={<Icon icon="icon-chevron-down" />}
	// 				style={{ verticalAlign: 'middle' }}>
	// 				{state?.i18n?.locales.map((locale: string) => {
	// 					return (
	// 						<DropdownItem key={`lang-${locale}`}>
	// 							<Link href={state.translatedUri && state.translatedUri[locale] ? state.translatedUri[locale] : `/${locale}/`}>
	// 								<Heading tag="span" size="texts" className={state.lang === locale ? 'color-primary50' : ''}>
	// 									{state?.i18n?.languages.filter((x: Language) => x.code === locale)[0].name}
	// 								</Heading>
	// 							</Link>
	// 						</DropdownItem>
	// 					);
	// 				})}
	// 			</Dropdown>
	// 		)}
	// 	</>
	// );
};

export default LanguageSwitcher;
